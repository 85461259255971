<template>
  <div>
    <vx-card>
      <vue-bootstrap4-table
          :rows="data"
          :columns="columns"
          :config="config"
          @on-change-query="onChangeQuery"
          @on-change-per-page="onChangePerPage"
          :total-rows="total_rows" >

        <template slot="row_actions" slot-scope="props">
          <router-link :to="'/contracts/'+ props.row.contract_id" >
            <vx-tooltip position="top" text="Bearbeiten" class="w-1/2">
            <vs-button color="warning" type="filled" icon="edit" size="small"></vs-button>
            </vx-tooltip>
          </router-link>
        </template>
          <template slot="domain" slot-scope="props">
            <span class="ml-5 p-2 align-middle">{{props.row.domain}}</span>
          </template>
        <template slot="host" slot-scope="props">
          <span class="host">{{props.row.host}}</span>
        </template>
        <template  slot-scope="props">
        <span  >
          <b>{{ props.row.cell_value}}</b>
       </span>
        </template>
        <template slot="contract_start" slot-scope="props">
          <b>
            {{formatDate(props.cell_value)}}
          </b>
        </template>
        <template slot="contract_end" slot-scope="props">
          <b>
            {{formatDate(props.cell_value)}}
          </b>
        </template>
        <template slot="ssl_certificate_issuer"  slot-scope="props">
             <span v-if="props.row.ssl_certificate_issuer === 'R3' || props.row.ssl_certificate_issuer === 'X3'"   class="lets-encrypt-certificate bg-warning ">
             <b>ACHTUNG: Lets Encrypt</b>
            </span>
            <span  v-else-if="props.row.ssl_certificate_issuer !== null && props.row.ssl_certificate_issuer.includes('Encryption Everywhere')"  class="basic-certificate bg-orange-500 ">
               <b>BASIC Zertifikat</b>
            </span>
          <span class="ssl-issuer" v-else>{{props.row.ssl_certificate_issuer}}</span>
        </template>
        <template slot="valid_to" slot-scope="props">
          <div class="expiry-date-container">
              <div class="circle--container col-2" >
                <vx-tooltip position="top" text="SSL Zertifikat Gültigkeit">
                  <div class="mega--circle green" v-if="daysBetweenToday(props.row.valid_to) > 6"></div>
                  <div class="mega--circle bg-orange-500"  v-else-if="daysBetweenToday(props.row.valid_to) <= 6 && daysBetweenToday(props.row.valid_to) >= 1 "> </div>
                  <div class="mega--circle red"  v-else-if="props.row.valid_to === null || daysBetweenToday(props.row.valid_to) <= 0"> </div>
                </vx-tooltip>
              </div>
            <b class="col-3" v-if="daysBetweenToday(props.row.valid_to) > 6"> gültig bis {{formatDate(props.cell_value)}}</b>
            <b class="col-3" v-else-if="daysBetweenToday(props.row.valid_to) <= 6 &&  daysBetweenToday(props.row.valid_to) >= 1"> gültig bis {{formatDate(props.cell_value)}}</b>
            <b class="col-3" v-else-if="props.row.valid_to === null || daysBetweenToday(props.row.valid_to) <= 0"> Keine Prüfung vorgenommen oder möglich</b>
          </div>
        </template>
        <template slot="empty-results">
          Keine Verträge gefunden.
        </template>
      </vue-bootstrap4-table>
    </vx-card>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
import ApiService from "../../../api";

import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";

export default {
  name: "SSL-Certificates",
  components: {
    VueBootstrap4Table,
  },
  data() {
    return {
      data: [],
      columns: [
        {
          label: "Domain",
          name: "domain",
          filter: {
            type: "simple",
            placeholder: "Domain suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "Host",
          slot_name: "host",
          filter: {
            type: "simple",
            placeholder: "Host suchen..."
          },
          sort: true,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: false,
        },
        {
          label: "Title",
          name: "product_name",
          filter: {
            type: "simple",
            placeholder: "Title suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Einzelpreis",
          name: "unit_price",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left'
        },
        {
          label: "Kunde",
          name: "contract.client.company_name",
          filter: {
            type: "simple",
            placeholder: "Firma suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Vertragsbeginn",
          name: "contracts.contract_start",
          slot_name: "contract_start",
          sort: true,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: true,
        },
        {
          label: "Vertragsende",
          name: "contracts.contract_end",
          slot_name: "contract_end",
          sort: true,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: true,
        },
        {
          label: "Zertifikats Emittent",
          name: "ssl_certificate_issuer",
          slot_name: "ssl_certificate_issuer",
          sort: true,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: true,
        },
        {
          label: "Live-Status",
          name: "valid_to",
          slot_name: "valid_to",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Actions",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'actions-column',
          showCol: true,
        }],
      config: {
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        per_page: 10,
        page: 1,
      },
      queryParams: {
        sort: [{
          caseSensitive: true,
          name: "valid_to",
          order: "asc"
        }],
        filters: [],
        global_search: "",
        page: 1,
      },
      total_rows: 0,

    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);
    }
  },
  methods: {
    fetchData() {
      this.$vs.loading()

      let filterParams = QueryHelper.parseRequestParams(this.queryParams);

      filterParams.filter.push(
          {
            field: 'active',
            value: 1,
            expression: 'exact',
          },
          {
            field: 'contracts.status',
            value: 3,
            expression: 'different',
          },
      );

      let filterGroup = {
        expression: 'group',
        filter: [{
          field: 'articles.article_type_id',
          value: 9,
          expression: 'exact',
        }, {
          field: 'article_assignment_id',
          value: 31,
          expression: 'exact',
          operator:'or'
        },
          {
            field: 'article_assignment_id',
            value: 18,
            expression: 'exact',
            operator:'or'
          },
          {
            field: 'article_assignment_id',
            value: 67,
            expression: 'exact',
            operator:'or'
          },
          ]
      };
      filterParams.filter.push(filterGroup)
      filterParams.includes =  "articles.type";

      ApiService.get('contract_items', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close()
      })
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },

    daysBetweenToday(value) {
      if (!value) return ''
      let now = moment();
      let end = moment(value);
      let duration = moment.duration(end.diff(now));
      return duration.asWeeks().toFixed(0)
    },

    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },

    onChangePerPage(value) {
      this.config.per_page = value;
    },
  }
}
</script>

<style lang="scss" scoped>
 .expiry-date-container {
   display:flex;
   min-width: 250px;
   padding: 0 12px;
 }

   .lets-encrypt-certificate {
     display: inline-block;

     color:#000;
     border-radius: 5px;
     padding: 6px;
     white-space: nowrap;
   }
   .basic-certificate {
     display: inline-block;
     color:#000;
     border-radius: 5px;
     padding: 6px;
     white-space: nowrap;

   }
   .ssl-issuer {
   padding: 3px;
   }
 .host {
   text-transform: capitalize;
 }
</style>